<template>
  <div>
    <b-row>
      <b-col
        md="6"
      >
        <b-card class="shadow-lg">
          <!-- swiper1 -->
          <swiper
            ref="swiperTop"
            class="swiper-gallery gallery-top"
            :options="swiperOptions"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          >
            <swiper-slide
              v-for="(data, index) in swiperData"
              :key="index"
            >
              <b-img
                :src="data"
                :style="{ height: '206px', width: '100%' }"
                fluid
              />
            </swiper-slide>

            <div
              slot="button-next"
              class="swiper-button-next swiper-button-white"
            />
            <div
              slot="button-prev"
              class="swiper-button-prev swiper-button-white"
            />
          </swiper>

          <!-- swiper2 Thumbs -->
          <swiper
            ref="swiperThumbs"
            class="swiper gallery-thumbs"
            :options="swiperOptionThumbs"
          >
            <swiper-slide
              v-for="(data, index) in swiperData"
              :key="index"
              class="text-center pt-1"
            >
              <b-img
                :src="data"
                :style="{ height: '50px' }"
                fluid
                thumbnail
                center
              />
            </swiper-slide>
          </swiper>
        </b-card>
      </b-col>
      <b-col
        md="6"
      >
        <b-card class="shadow-lg">
          <b-list-group>

            <b-list-group-item
              active
              class="d-flex justify-content-between align-items-center"
            >
              <span>Informations sur la propriété</span>
            </b-list-group-item>
            <b-list-group-item
              class="d-flex justify-content-between align-items-center"
            >
              <h6>Actuel propriétaire</h6>

              <div v-if="propriete.is_multiple_proprietaire===0">

                <span
                  v-text="propriete.proprietaire_actuel.name"
                />
              </div>
              <span v-else>Multi propriétaires</span>

            </b-list-group-item>
            <b-list-group-item
              class="d-flex justify-content-between align-items-center"
            >
              <h6>Nom de la propriété</h6>
              <span class="text-capitalize"> {{ propriete.name }}</span>
            </b-list-group-item>
            <b-list-group-item
              class="d-flex justify-content-between align-items-center"
            >
              <h6>Type de propriété</h6>
              {{ propriete.type_propriete.title }}
            </b-list-group-item>
            <b-list-group-item
              class="d-flex justify-content-between align-items-center"
            >
              <h6>Adresse</h6>
              Cotonou
            </b-list-group-item>

            <b-list-group-item
              class="d-flex justify-content-between align-items-center"
            >
              <h6>Etat</h6>
              <b-badge
                pill
                variant="success"
              >
                Occupé
              </b-badge>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
      <b-col
        md="12"
        class="mb-3"
      >

        <b-card class="shadow-lg">
          <b-tabs
            justified
            pills
            nav-class="nav-pill-danger"
          >
            <b-tab
              title="Informations complémentaires"
              active
            >
              <b-row class="justify-content-center">
                <b-col md="6">
                  <b-list-group>

                    <b-list-group-item
                      variant="info"
                      class="d-flex justify-content-between align-items-center"
                    >
                      <span>Informations sur la propriété</span>
                    </b-list-group-item>
                    <b-list-group-item
                      class="d-flex justify-content-between align-items-center"
                    >
                      <h6>Actuel propriétaire</h6>
                      {{ propriete.is_multiple_proprietaire===0? propriete.proprietaire_actuel.name :'Multi propriétaires' }}
                    </b-list-group-item>
                    <b-list-group-item
                      class="d-flex justify-content-between align-items-center"
                    >
                      <h6>Nom de la propriété</h6>
                      {{ propriete.name }}
                    </b-list-group-item>
                    <b-list-group-item
                      class="d-flex justify-content-between align-items-center"
                    >
                      <h6>Type de propriété</h6>
                      {{ propriete.type_propriete.title }}
                    </b-list-group-item>
                    <b-list-group-item
                      class="d-flex justify-content-between align-items-center"
                    >
                      <h6>Adresse</h6>
                      Cotonou
                    </b-list-group-item>

                    <b-list-group-item
                      class="d-flex justify-content-between align-items-center"
                    >
                      <h6>Etat</h6>
                      <b-badge
                        pill
                        variant="success"
                      >
                        Occupé
                      </b-badge>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
              </b-row>

            </b-tab>
            <b-tab
              v-if="propriete.appartements.length !=0"
              title="Historique des locataires"
            >
              <b-card
                no-body
                class="card-company-table"
              >
                <b-table
                  :items="propriete.appartements"
                  responsive
                  :fields="propertyLocataireFields"
                  class="mb-0"
                  show-empty
                >
                  <template #table-busy>
                    <div class="d-flex justify-content-center mb-3">
                      <b-spinner
                        style="width: 2rem; height: 2rem;"
                        variant="primary"
                        label="Large Spinner"
                        type="grow"
                        class="mt-2"
                      />
                      <span class="sr-only">Chargement en cours...</span>
                    </div>
                  </template>
                  <template #empty>
                    <div class="d-flex justify-content-center">
                      <b-img
                        fluid
                        :style="{ width: '50%' }"
                        :src="require('@/assets/images/no_result.gif')"
                      />
                    </div>
                  </template>
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template #cell(actions)="data">

                    <feather-icon
                      v-b-modal.modal-locataire
                      class="text-primary ml-2"
                      icon="EyeIcon"
                      @click="getItem(data.item)"
                    />
                  </template>
                </b-table>
              </b-card>
            </b-tab>
            <b-tab
              v-else
              title="Historique des locataires"
            >
              <b-card
                no-body
                class="card-company-table"
              >
                <b-table
                  :items="propriete.historique_locataires"
                  responsive
                  :fields="historicLandlordFields"
                  class="mb-0"
                  show-empty
                >
                  <template #table-busy>
                    <div class="d-flex justify-content-center mb-3">
                      <b-spinner
                        style="width: 2rem; height: 2rem;"
                        variant="primary"
                        label="Large Spinner"
                        type="grow"
                        class="mt-2"
                      />
                      <span class="sr-only">Chargement en cours...</span>
                    </div>
                  </template>
                  <template #empty>
                    <div class="d-flex justify-content-center">
                      <b-img
                        fluid
                        :style="{ width: '50%' }"
                        :src="require('@/assets/images/no_result.gif')"
                      />
                    </div>
                  </template>
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>
                </b-table>
              </b-card>
            </b-tab>
            <b-tab
              title="Historique des propriétaires"
            >
              <b-card
                no-body
                class="card-company-table"
              >
                <b-table
                  :items="propriete.historique_proprietaires"
                  responsive
                  :fields="historicLandlordFields"
                  class="mb-0"
                  show-empty
                >
                  <template #table-busy>
                    <div class="d-flex justify-content-center mb-3">
                      <b-spinner
                        style="width: 2rem; height: 2rem;"
                        variant="primary"
                        label="Large Spinner"
                        type="grow"
                        class="mt-2"
                      />
                      <span class="sr-only">Chargement en cours...</span>
                    </div>
                  </template>
                  <template #empty>
                    <div class="d-flex justify-content-center">
                      <b-img
                        fluid
                        :style="{ width: '50%' }"
                        :src="require('@/assets/images/no_result.gif')"
                      />
                    </div>
                  </template>
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template #cell(roles)="data">

                    <b-badge
                      v-for="item in data.item.roles"
                      :key="`jour-${item.id}`"
                      pill
                      class="ml-1"
                      variant="light-primary"
                    >
                      {{ item.libelle }}

                    </b-badge>
                  </template>

                </b-table>
              </b-card>
            </b-tab>
            <b-tab title="Appartements" v-if="propriete.appartements.length !=0">
              <b-card
                no-body
                class="card-company-table"
              >
                <b-table
                  :items="propriete.appartements"
                  responsive
                  :fields="propertyFields"
                  class="mb-0"
                  show-empty
                >
                  <template #table-busy>
                    <div class="d-flex justify-content-center mb-3">
                      <b-spinner
                        style="width: 2rem; height: 2rem;"
                        variant="primary"
                        label="Large Spinner"
                        type="grow"
                        class="mt-2"
                      />
                      <span class="sr-only">Chargement en cours...</span>
                    </div>
                  </template>
                  <template #empty>
                    <div class="d-flex justify-content-center">
                      <b-img
                        fluid
                        :style="{ width: '50%' }"
                        :src="require('@/assets/images/no_result.gif')"
                      />
                    </div>
                  </template>
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template #cell(actions)="data">

                    <feather-icon
                      v-b-modal.modal-image
                      class="text-primary ml-2"
                      icon="EyeIcon"
                      @click="getImage(data)"
                    />
                    </template>
                  

                </b-table>
              </b-card>
            </b-tab>
          </b-tabs>
        </b-card>

      </b-col>
      <b-col
        v-if="propriete.hidden_location"
        md="12"
        class="mb-3"
      >

        <b-card class="shadow-lg">
          <h2 class="mb-2">
            Géolocalisation
          </h2>

          <l-map
            ref="map"
            :zoom="zoom"
            :center="userLocation"
            :options="mapOptions"
            style="height: 255px;"
          >

            <l-tile-layer
              :name="tileProvider.name"
              :visible="tileProvider.visible"
              :url="tileProvider.url"
              :attribution="tileProvider.attribution"
              layer-type="base"
            />
            <l-circle
              :lat-lng.sync="userLocation"
              :radius="circle.radius"
              :color="circle.color"
            />
            <l-control-attribution
              :position="attributionPosition"
              :prefix="attributionPrefix"
            />

            <l-marker
              v-if="userLocation.lat && userLocation.lng"
              visible
              :icon="iconT"
              draggable
              :lat-lng.sync="userLocation"
              @dragstart="dragging = true"
              @dragend="dragging = false"
            >
              <l-tooltip
                :content="tooltipContent"
                :options="{ permanent: false }"
              />
            </l-marker>
          </l-map>
        </b-card>

      </b-col>

      <b-modal
        id="modal-image"
        ref="myModal"
        button-size="sm"
        title="Image appartement"
        ok-only
        ok-title="Fermer"
      >
        <b-card
          :img-src="imageUrl"
          img-top
          img-alt=""
          class="position-static"
        />

      </b-modal>
      <b-modal
        id="modal-locataire"
        ref="myModal"
        button-size="sm"
        title="Historique des locataires"
        ok-only
        ok-title="Fermer"
        size="lg"
      >
        <b-card
          no-body
          class="card-company-table"
        >
          <b-table
            :items="historicLocataire"
            responsive
            :fields="historicLocateThemFields"
            class="mb-0"
            show-empty
          >
            <template #table-busy>
              <div class="d-flex justify-content-center mb-3">
                <b-spinner
                  style="width: 2rem; height: 2rem;"
                  variant="primary"
                  label="Large Spinner"
                  type="grow"
                  class="mt-2"
                />
                <span class="sr-only">Chargement en cours...</span>
              </div>
            </template>
            <template #empty>
              <div class="d-flex justify-content-center">
                <b-img
                  fluid
                  :style="{ width: '50%' }"
                  :src="require('@/assets/images/no_result.gif')"
                />
              </div>
            </template>
            <template v-slot:cell(index)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell(actions)="data">

              <feather-icon
                v-b-modal.modal-image
                class="text-primary ml-2"
                icon="EyeIcon"
                @click="getImage(data)"
              />

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-tooltip.hover.bottom="'Copier appartement'"
                v-b-modal.modal-preview
                variant="gradient-danger"
                size="sm"
                class="btn-icon rounded-circle ml-1"
                @click.prevent="copyPixCodeClick(data.item.id)"
              >
                <feather-icon icon="Minimize2Icon" />
              </b-button>
            </template>

          </b-table>
        </b-card>

      </b-modal>
    </b-row>

  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import {
  BRow, BCol, BImg, BCard,
  BBadge,
  BListGroup,
  BListGroupItem,
  BTable,
  BTabs, BTab,
  VBTooltip,
} from 'bootstrap-vue'
import 'swiper/css/swiper.css'

import {
  onMounted, ref, reactive, computed,
} from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
// eslint-disable-next-line import/no-cycle
import useProprietes from '@/composables/proprieteService'
import { $themeConfig } from '@themeConfig'

import {
  LMap, LTileLayer, LMarker, LControlAttribution, LCircle, LTooltip,
} from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import { Icon, icon } from 'leaflet'

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  // eslint-disable-next-line global-require
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  // eslint-disable-next-line global-require
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  // eslint-disable-next-line global-require
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default {
  components: {
    Swiper,
    SwiperSlide,
    BImg,
    BRow,
    BCol,
    BCard,
    BBadge,
    BListGroup,
    BListGroupItem,
    BTable,
    BTabs,
    BTab,

    LMap,
    LTileLayer,
    LControlAttribution,
    LCircle,
    LMarker,
    LTooltip,

  },
  
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    const { SITE_URL } = $themeConfig.app

    const {
      getProprieteById, propriete,
    } = useProprietes()

    /* eslint-disable global-require */
    const swiperData = ref([])
    /* eslint-disable global-require */

    const swiperOptions = ref({
      loop: true,
      loopedSlides: 5,
      spaceBetween: 10,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })

    const tableData = ref([])
    const imageUrl = ref('')
    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'copropriete', label: 'Copropriété' },
      { key: 'roles', label: 'Rôles' },
    ]
    const propertyFields = [
      { key: 'index', label: 'N°' },
      { key: 'libelle', label: 'Libellé' },
      { key: 'residence', label: 'Type' },
      { key: 'loyer', label: 'Loyer' },
      { key: 'actions', label: 'Actions' },
    ]
    const historicLandlordFields = [
      { key: 'index', label: 'N°' },
      { key: 'name', label: 'Nom' },
      { key: 'email', label: 'email' },
      { key: 'phone', label: 'Téléphone' },
      { key: 'created_at', label: 'Date' },
    ]
    const propertyLocataireFields = [
      { key: 'index', label: 'N°' },
      { key: 'libelle', label: 'Libellé' },
      { key: 'historique_locataires.length', label: 'Nombre de locataire' },
      { key: 'actions', label: 'Actions' },
    ]
    const swiperOptionThumbs = ref({
      loop: true,
      loopedSlides: 5, // looped slides should be the same
      spaceBetween: 5,
      centeredSlides: true,
      slidesPerView: 4,
      touchRatio: 0.2,
      slideToClickedSlide: true,
    })
    const swiperTop = ref('')
    const swiperThumbs = ref('')

    const historicLocateThemFields = [
      { key: 'index', label: 'N°' },
      { key: 'name', label: 'Nom' },
      { key: 'email', label: 'email' },
      { key: 'phone', label: 'Téléphone' },
      { key: 'created_at', label: 'Date' },
    ]
    const position = ref({})
    const zoom = ref(10)
    const loading = ref(false)
    const attributionPrefix = ref('Logis Connect')
    const attributionPosition = ref('bottomright')
    const userLocation = reactive({
      lat: null,
      lng: null,
    })
    const map = ref('')
    const mapOptions = reactive({
      zoomControl: true,
      attributionControl: false,
      zoomSnap: true,
    })
    const getImage = data => {      
      imageUrl.value = data.item.propriete_image.image_url
    }
    const historicLocataire = ref([])

    const getItem = data => {
      historicLocataire.value = data.historique_locataires
    }
    const circle = reactive({
      radius: 300,
      color: '#EA5455',
    })
    const tileProvider = ref({
      name: 'OpenStreetMap',
      visible: true,
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    })
    const iconT = icon({
      // eslint-disable-next-line global-require
      iconUrl: require('@/assets/images/location.gif'),
      iconSize: [40, 80],
      iconAnchor: [16, 37],
    })

    const dragging = ref(false)
    const address = ref(null)
    const getAddress = async () => {
      loading.value = true
      address.value = 'Unresolved address'
      try {
        const { lat, lng } = userLocation
        const result = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`,
        )
        if (result.status === 200) {
          const body = await result.json()
          address.value = body.display_name
        }
      } catch (e) {
        console.error('Reverse Geocode Error->', e)
      }
      loading.value = false
    }
    const onSearch = value => {
      const loc = value.location
      position.value = { lat: loc.y, lng: loc.x }
    }

    // eslint-disable-next-line no-nested-ternary
    const tooltipContent = computed(() => (dragging.value ? '...' : loading.value ? 'Loading...' : address.value !== null ? `<strong>${address.value.replace(
      ',', '<br/>',
    )}</strong> <hr/><strong>lat:</strong> ${userLocation.lat
    }<br/> <strong>lng:</strong> ${userLocation.lng}` : ''))

    onMounted(async () => {
      await getProprieteById(router.currentRoute.params.id)
      if (propriete.value.type_propriete_id === 1) {
        swiperData.value = propriete.value.image_appartements
      } else {
        swiperData.value = propriete.value.propriete_images.map(v => `${SITE_URL}storage/uploads/${v.image}`)
      }
      if (swiperData.value.length === 0) {
        swiperData.value = [`${SITE_URL}storage/default/img-1.jpg`]
      }
      swiperTop.value = swiperTop.value.$swiper
      swiperThumbs.value = swiperThumbs.value.$swiper
      swiperTop.value.controller.control = swiperThumbs.value
      swiperThumbs.value.controller.control = swiperTop.value

      userLocation.lat = propriete.value.latitude
      userLocation.lng = propriete.value.longitude
      

      console.log(propriete)

      
      await getAddress()
      // eslint-disable-next-line no-prototype-builtins
      map.value.mapObject.on('geosearch/showlocation', onSearch)
    })
    return {
      swiperData,
      swiperOptions,
      swiperOptionThumbs,
      swiperTop,
      swiperThumbs,
      tableData,
      fields,
      propriete,
      historicLandlordFields,
      propertyLocataireFields,
      getItem,
      historicLocataire,
      historicLocateThemFields,

      dragging,
      map,
      zoom,
      iconT,
      attributionPrefix,
      attributionPosition,
      mapOptions,
      userLocation,
      circle,
      tileProvider,
      address,
      getAddress,
      onSearch,
      tooltipContent,
      position,
      propertyFields,
      getImage,
      imageUrl,

    }
  },
}
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
